import { UrnResource, extractIdFromUrn } from "@santa/common/lib/utils/urn";
import queryString from "query-string";
import TagManager from "react-gtm-module";

import { config } from "../../config";

interface IOrder {
  urn: string;
  orderItems: {
    productAlphaId: string;
    price: number;
  }[];
  promoCode?: {
    code: string;
  } | null;
  promoDiscount?: number | null;
}

/**
 * Track the conversion with Google Tag Manager
 * @param orderUrn URN of order
 * @param orderItems Items ordered
 */
export const trackGtmConversion = (order: IOrder): void => {
  if (config.tracking.google) {
    const value = Number(order.orderItems.reduce<number>((acc, i) => acc + i.price, 0).toFixed(2));

    TagManager.dataLayer({
      dataLayer: {
        event: "Order confirmation",
        details: {
          value,
          productIds: order.orderItems.map(i => i.productAlphaId),
          currency: config.site.currency,
          urn: order.urn,
        },
      },
    });
  }
};
